<template>
  <section class="invite-section">
    <div class="invite-container">
      <h1 class="invite-title">
        Stay active. <span>Stay productive.</span>
      </h1>
      <span class="invite-subtitle">
        Simple time tracking app
      </span>
      <template
        v-if="inviteStatus"
      >
        <validation-observer ref="observer">
          <v-form
            ref="form"
            class="invite-form"
            @submit.prevent="authMethod"
          >
            <div class="invite-text">
              <img class="invite-img" src="@/static/welcome.png" alt="">
              <h2 class="mb-3">
                Welcome to Timeprism
              </h2>
              <span>
                You’ve been invited by
              </span>
              <b>
                {{ message }}
              </b>
            </div>
            <validation-provider
              v-slot="{ errors }"
              name="name"
              rules="required|max:150"
            >
              <text-field
                v-model="formData.name"
                class="mb-2"
                outlined
                placeholder="Enter your name"
                full-size
                :error-messages="errors"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="password"
              rules="required|max:255|min:6"
            >
              <text-field
                v-model="formData.password"
                class="mb-2"
                outlined
                full-size
                placeholder="Password"
                :append-icon="passwordType ? 'visibility' : 'visibility_off'"
                :error-messages="confirm ? confirmText : errors"
                :type="!passwordType ? 'password' : 'text'"
                @click:append="passwordType = !passwordType"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="confirm_password"
              rules="required|max:255|min:6"
            >
              <text-field
                v-model="formData.password_confirmation"
                class="mb-2"
                outlined
                full-size
                :append-icon="confirmPasswordType ? 'visibility' : 'visibility_off'"
                :error-messages="confirm ? confirmText : errors"
                placeholder="Confirm Password"
                :type="!confirmPasswordType ? 'password' : 'text'"
                @click:append="confirmPasswordType = !confirmPasswordType"
              />
            </validation-provider>
            <primary-button
              type="submit"
              class="mb-5"
              full-width
              :loading="loading"
            >
              Create Account
            </primary-button>
            <span v-if="errorMessage" class="error-message">
              {{ errorMessage }}
            </span>
          </v-form>
        </validation-observer>
      </template>
      <div v-else class="invite-form">
        <div class="invite-text">
          <img class="invite-img" src="@/static/welcome.png" alt="">
          <h2 class="mb-3">
            Welcome to Timeprism
          </h2>
          <span>
            {{ message }}
          </span>
        </div>
        <primary-button
          class="mb-5"
          full-width
          @click="$router.push('Login')"
        >
          Sign In
        </primary-button>
      </div>
    </div>
    <div class="about-dev">
      Powered by <a href="https://redentu.com" target="_blank">Redentu</a>
    </div>
  </section>
</template>

<script>
import store from '@/store';
import TextField from '@/components/ui/TextField/TextField';
import PrimaryButton from '@/components/ui/Buttons/PrimaryButton';
import { mapGetters } from 'vuex';

export default {
  name: 'Invite',
  components: { PrimaryButton, TextField },
  beforeRouteEnter: (to, from, next) => {
    if (store.state.auth.isAuthorized) {
      next({
        name: 'Activity',
        params: {
          workspace: store.state.publicStore.selectedWorkspace.slug,
        },
      });
    }

    next();
  },

  data() {
    return {
      loading: false,
      message: '',
      formData: {
        confirm: true,
        name: '',
        password: '',
        password_confirmation: '',
        code: '',
        timezone: '',
      },
      confirm: false,
      confirmText: 'Password must match',
      passwordType: false,
      confirmPasswordType: false,
    };
  },
  computed: {
    ...mapGetters({
      errorMessage: 'getErrorMsg',
      inviteStatus: 'getInviteStatus',
    }),
  },
  watch: {
    formData: {
      deep: true,
      handler(val) {
        this.confirm = !!(val.password !== val.password_confirmation
          && val.password_confirmation.length);
      },
    },
  },
  created() {
    this.checkInviteStatus();
    this.formData.code = this.$route.query.code;
    this.formData.timezone = this.$moment.tz.guess();
    this.$store.commit('updateErrorMsg', null);
    this.confirm = false;
  },
  methods: {
    checkInviteStatus() {
      const { code } = this.$route.query;
      this.$store.dispatch('GET_INVITE_INFO', {
        params: {
          code,
        },
      }).then((response) => {
        if (Object.keys(response).length > 0) {
          Object.keys(response).forEach((key) => {
            switch (key) {
              case 'invited_by':
                this.message = response[key];
                break;
              case 'message':
                this.message = response[key];
                break;
              case 'workspace':
                this.$router.push('/dashboard');
                break;
              default:
                break;
            }
          });
        }
      });
    },
    authMethod() {
      const observerRef = this.$refs.observer;
      observerRef.validate().then((isValid) => {
        if (isValid) {
          this.loading = true;
          this.$store.dispatch('CONFIRM_INVITE', {
            formData: this.formData,
          })
            .then(() => {
              this.loading = false;
              this.$router.push('/dashboard');
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .invite {
    &-section {
      background-image: url("../../static/invite-bg.png");
      background-position: center top;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 150px;
      padding-bottom: 25px;
    }

    &-text {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 35px;
    }

    &-form {
      padding: 50px 95px;
      background: $white;
      min-width: 558px;
      border-radius: $radius-4;
      margin-bottom: 65px;
    }

    &-img {
      margin-bottom: 42px;
    }

    &-title {
      font-size: 48px;
      color: $white;

      span {
        font-size: inherit;
        color: $grey;
      }
    }

    &-subtitle {
      display: block;
      color: $grey-light;
      text-align: center;
      margin-bottom: 43px;
    }

    &-container {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  .about-dev {
    a {
      text-decoration: none;
    }
  }
</style>
